import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 600.000000 600.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,600.000000) scale(0.100000,-0.100000)">

<path d="M3170 4144 c-14 -9 -31 -24 -37 -32 -17 -21 -16 -82 1 -101 13 -14
10 -25 -30 -90 -34 -57 -42 -77 -33 -87 19 -23 27 -17 73 55 38 59 49 69 81
75 53 10 85 46 85 95 0 76 -81 125 -140 85z"/>
<path d="M2618 4004 c-36 -19 -61 -68 -53 -103 10 -48 49 -76 105 -76 34 0 57
-6 79 -22 36 -28 47 -29 56 -6 4 13 -3 26 -24 45 -26 23 -29 32 -24 62 7 45
-16 82 -62 102 -42 17 -43 17 -77 -2z"/>
<path d="M2895 3791 c-11 -5 -35 -24 -53 -42 -127 -130 18 -343 183 -270 55
25 80 49 96 98 37 112 -42 224 -155 222 -28 0 -60 -4 -71 -8z"/>
<path d="M3348 3661 c-32 -28 -43 -31 -98 -31 -58 0 -61 -1 -58 -22 3 -20 10
-23 56 -26 48 -3 55 -6 73 -36 44 -75 134 -73 174 4 30 58 -8 125 -77 136 -29
5 -41 1 -70 -25z"/>
<path d="M2454 3561 c-20 -12 -37 -34 -44 -55 -10 -31 -9 -41 7 -69 49 -83
160 -67 183 27 4 16 24 27 78 46 51 18 72 30 72 42 0 29 -18 30 -88 8 l-69
-22 -34 21 c-42 26 -65 26 -105 2z"/>
<path d="M2998 3405 c-2 -3 0 -30 5 -60 10 -51 9 -57 -14 -81 -16 -18 -24 -37
-24 -64 0 -97 136 -136 180 -51 30 58 12 112 -47 138 -21 9 -38 24 -38 32 0
41 -22 91 -39 91 -10 0 -20 -2 -23 -5z"/>
<path d="M3733 3028 c-44 -11 -73 -52 -73 -100 0 -40 14 -77 34 -90 15 -10
-16 -20 -51 -16 -21 2 -27 8 -25 22 5 26 -16 36 -45 21 -30 -16 -59 -9 -78 18
-21 30 -19 59 6 86 21 23 49 25 91 6 12 -5 20 -2 24 9 19 50 -91 66 -144 21
-48 -40 -50 -94 -5 -176 2 -4 -10 -6 -28 -5 -23 1 -34 8 -40 24 -10 27 -39 29
-46 2 -11 -40 -31 -18 -71 78 -34 80 -44 98 -64 100 -21 3 -28 -7 -65 -95 -37
-88 -44 -98 -71 -104 -47 -10 -53 0 -50 82 3 72 4 74 31 77 19 2 27 9 27 23 0
17 -8 19 -80 19 -73 0 -80 -2 -80 -20 0 -16 7 -20 30 -20 l30 0 0 -79 c0 -44
-3 -82 -7 -84 -5 -3 -22 -2 -40 0 -29 5 -34 12 -71 102 -34 81 -44 96 -65 99
-22 3 -28 -6 -64 -95 -35 -84 -44 -98 -69 -105 -36 -9 -40 -5 -20 24 18 25 21
99 6 129 -17 30 -64 49 -125 49 l-55 0 0 -100 c0 -80 -3 -100 -14 -100 -8 0
-16 5 -18 11 -2 7 -22 12 -43 13 l-40 1 -3 88 c-3 85 -3 87 -27 87 -25 0 -25
0 -25 -100 0 -91 -2 -100 -19 -100 -14 0 -24 16 -41 63 -39 106 -58 137 -83
137 -19 0 -28 -15 -62 -100 -37 -92 -57 -119 -67 -88 -2 6 -26 12 -54 12 -43
1 -49 4 -52 23 -3 20 2 22 45 25 36 2 49 7 51 21 3 14 -4 17 -48 17 -48 0 -51
2 -48 23 3 19 10 22 56 25 44 3 52 6 52 23 0 17 -7 19 -75 19 l-75 0 0 -100 0
-100 -28 0 c-20 0 -32 8 -46 31 -16 28 -17 32 -2 48 9 10 16 34 16 54 0 50
-27 67 -107 67 l-63 0 0 -100 c0 -93 -1 -100 -20 -100 -11 0 -20 4 -20 9 0 6
-24 11 -52 13 -46 3 -53 6 -56 25 -3 20 2 22 45 25 35 2 48 7 48 18 0 10 -14
17 -45 20 -37 4 -45 9 -45 25 0 17 8 20 53 23 44 3 52 6 52 23 0 17 -7 19 -75
19 l-75 0 0 -99 c0 -93 -1 -99 -22 -104 -12 -3 -25 -4 -30 -1 -4 3 -8 50 -8
105 0 99 0 99 -25 99 -22 0 -24 -4 -21 -32 6 -48 -2 -58 -45 -58 l-39 0 0 45
c0 41 -2 45 -25 45 l-25 0 0 -106 0 -105 -42 3 -43 3 -3 83 -3 82 31 0 c23 0
30 4 30 20 0 18 -7 20 -85 20 -78 0 -85 -2 -85 -20 0 -16 7 -20 30 -20 l31 0
-3 -82 c-3 -82 -3 -83 -30 -83 -14 0 -30 -3 -34 -7 -4 -5 52 -8 124 -8 l132 0
0 45 0 45 40 0 c42 0 40 3 44 -62 1 -23 3 -23 174 -26 l172 -2 0 35 c0 48 30
48 60 0 l22 -35 158 0 158 0 6 25 c6 22 12 25 54 25 39 0 49 -4 57 -22 10 -23
11 -23 263 -26 l252 -2 12 25 c10 22 18 25 59 25 42 0 48 -3 53 -25 l6 -25
140 0 c140 0 140 0 152 25 10 22 18 25 59 25 42 0 48 -3 53 -25 l6 -25 343 2
342 3 5 60 5 60 25 -49 c14 -28 30 -52 37 -54 7 -2 26 23 42 55 l30 58 1 -67
0 -68 115 0 115 0 0 65 c0 36 3 65 8 65 4 -1 28 -30 55 -65 l47 -65 146 0
c111 0 143 3 138 12 -4 7 -7 31 -5 55 2 40 1 43 -23 43 -22 0 -26 -4 -26 -29
0 -37 -30 -50 -65 -27 -71 46 -16 152 60 118 18 -9 26 -8 36 5 34 40 -97 62
-139 23 -36 -34 -45 -86 -22 -138 11 -25 20 -47 20 -49 0 -2 -17 -3 -37 -1
l-38 4 -3 102 c-2 94 -4 102 -22 102 -18 0 -20 -7 -20 -65 0 -36 -3 -65 -7
-65 -5 0 -28 29 -53 65 -32 46 -51 65 -67 65 -21 0 -22 -4 -25 -102 -3 -89 -5
-101 -21 -104 -11 -1 -22 7 -27 22 -12 31 -38 31 -46 -2 -4 -16 -12 -24 -23
-22 -13 3 -16 20 -19 106 l-3 102 -33 0 c-31 0 -35 -4 -57 -55 -13 -30 -27
-55 -30 -55 -4 0 -17 25 -30 55 -22 50 -27 55 -56 55 l-32 0 -3 -102 -3 -103
-37 -2 c-43 -2 -44 -1 -21 33 9 14 16 45 16 70 0 77 -63 122 -140 102z m-1954
-52 c16 -19 3 -45 -25 -52 -32 -8 -44 2 -44 37 0 25 4 29 29 29 16 0 34 -6 40
-14z m832 -3 c5 -5 11 -22 15 -39 7 -39 -20 -74 -61 -77 l-30 -2 -3 69 -3 69
37 -6 c21 -4 41 -10 45 -14z m1203 -2 c43 -47 -13 -138 -69 -113 -29 13 -49
55 -40 83 16 49 77 66 109 30z m-1630 -27 c11 -29 6 -44 -14 -44 -11 0 -20 6
-20 14 0 21 11 46 20 46 4 0 11 -7 14 -16z m643 -28 c-2 -5 -11 -10 -20 -12
-18 -2 -22 13 -10 42 6 16 7 16 20 -2 7 -10 12 -22 10 -28z m407 22 c9 -34 8
-37 -14 -35 -21 1 -25 13 -14 41 9 23 21 20 28 -6z"/>
<path d="M3861 2782 c-5 -10 -15 -49 -22 -85 -8 -41 -17 -67 -25 -67 -19 0
-29 21 -25 52 3 36 -26 38 -57 5 -38 -40 -17 -101 22 -68 10 8 16 9 21 1 3 -5
19 -10 36 -10 24 0 32 7 46 36 l17 35 12 -33 c11 -32 12 -33 72 -35 34 -1 64
3 68 8 3 6 11 5 21 -2 11 -10 19 -10 33 -1 12 7 21 8 25 2 10 -17 87 -11 108
8 15 12 21 14 24 5 6 -16 35 -17 51 -1 9 9 12 8 12 -5 0 -9 5 -17 10 -17 15 0
12 27 -4 33 -8 3 -24 3 -37 0 -21 -6 -22 -4 -15 33 19 98 19 97 5 92 -8 -3
-18 -18 -23 -36 -6 -20 -17 -32 -33 -37 -15 -3 -29 -17 -36 -35 -7 -18 -19
-30 -29 -30 -14 0 -18 8 -18 40 0 51 -23 54 -66 9 -35 -37 -61 -52 -75 -43 -7
4 -5 12 4 22 23 28 28 52 11 59 -19 7 -35 -8 -48 -49 -15 -45 -34 -46 -42 -4
-4 23 -12 36 -22 36 -12 0 -13 3 -4 12 14 14 16 88 2 88 -5 0 -14 -8 -19 -18z
m-91 -101 c0 -5 -9 -19 -20 -32 -15 -19 -19 -21 -20 -7 0 9 7 24 16 32 18 18
24 20 24 7z m320 -21 c-13 -23 -30 -35 -30 -20 0 20 21 51 31 45 6 -4 5 -13
-1 -25z m130 5 c0 -8 -9 -19 -20 -25 -15 -8 -20 -8 -20 3 0 14 19 37 32 37 4
0 8 -7 8 -15z"/>
<path d="M2941 2752 c-6 -11 -11 -28 -11 -36 0 -9 -7 -16 -16 -16 -9 0 -34
-16 -55 -35 -39 -35 -63 -43 -73 -26 -3 4 3 16 14 26 23 21 27 55 7 55 -20 0
-45 -31 -49 -60 -2 -17 -9 -25 -23 -25 -27 0 -32 31 -5 38 11 3 20 11 20 17 0
7 -7 10 -16 6 -14 -5 -16 -1 -11 24 8 39 -5 39 -20 -1 -11 -28 -15 -30 -43
-24 -26 6 -37 1 -70 -29 -22 -20 -44 -36 -48 -36 -20 0 -20 14 0 40 11 16 17
33 14 39 -14 22 -35 10 -58 -31 -31 -56 -52 -62 -44 -14 6 38 -4 45 -34 26
-11 -7 -17 -5 -21 7 -4 13 -10 7 -25 -22 -26 -52 -44 -57 -44 -11 0 37 -15 47
-38 24 -9 -9 -12 -8 -12 5 0 25 -17 21 -24 -7 -3 -13 -13 -26 -21 -30 -11 -4
-15 2 -15 25 0 29 -2 31 -27 25 -16 -4 -38 -18 -50 -32 -23 -25 -79 -51 -89
-41 -3 3 0 15 6 27 8 15 17 19 32 14 15 -4 19 -2 16 7 -8 24 -45 22 -62 -3
-21 -30 -20 -55 2 -62 25 -7 122 0 160 12 26 9 32 8 32 -4 0 -23 15 -16 40 19
l25 32 5 -30 c5 -26 9 -30 39 -30 25 -1 39 7 57 30 l24 30 0 -28 c0 -31 24
-46 49 -30 11 7 23 7 39 0 15 -7 29 -7 46 1 16 7 27 7 34 0 7 -7 25 -8 49 -3
21 5 43 7 48 5 29 -10 197 -6 212 5 13 10 21 10 35 1 14 -8 23 -8 38 2 29 18
33 15 26 -14 -5 -19 -3 -25 6 -22 7 3 14 17 16 33 4 27 3 28 -37 22 -46 -6
-49 -1 -29 65 13 46 -2 75 -21 40z m-264 -82 c25 0 9 -23 -22 -32 -37 -9 -48
-2 -34 23 6 12 17 18 26 15 8 -3 21 -6 30 -6z m243 1 c0 -5 -8 -17 -17 -27
-20 -20 -32 -1 -13 21 14 17 30 20 30 6z m-740 -15 c0 -16 -18 -31 -27 -22 -8
8 5 36 17 36 5 0 10 -6 10 -14z"/>
<path d="M1637 2753 c-4 -3 -7 -14 -7 -24 0 -10 -6 -24 -14 -32 -8 -8 -14 -22
-13 -33 1 -11 -6 -20 -17 -22 -17 -4 -18 0 -13 32 7 42 -1 45 -33 11 -13 -14
-29 -25 -37 -25 -19 0 -17 -34 2 -35 6 -1 22 -5 37 -9 16 -5 35 -3 48 4 15 8
25 8 34 1 14 -12 83 -12 107 0 9 5 20 4 27 -3 5 -5 28 -9 50 -6 l40 3 -24 -41
c-30 -50 -32 -94 -5 -94 26 0 37 18 45 71 4 27 15 54 26 64 26 23 25 38 0 30
-18 -6 -20 -3 -14 19 9 38 -12 32 -27 -6 l-13 -33 -4 38 c-3 40 -22 52 -22 15
0 -33 -15 -52 -35 -44 -12 4 -16 15 -13 38 3 29 0 33 -22 33 -19 0 -29 -9 -43
-37 -12 -24 -26 -38 -37 -38 -37 0 -33 29 5 44 20 8 20 8 -1 16 -16 6 -20 15
-16 39 4 31 1 37 -11 24z m92 -105 c-12 -20 -14 -21 -17 -5 -2 10 2 25 9 34
18 21 23 0 8 -29z m-179 2 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11
10 2 0 4 -4 4 -10z m294 -107 c-8 -35 -24 -54 -24 -29 0 18 17 56 26 56 2 0 1
-12 -2 -27z"/>
<path d="M3310 2734 c-6 -14 -10 -30 -10 -35 0 -5 -4 -9 -10 -9 -5 0 -9 -10
-7 -22 1 -14 -4 -23 -15 -26 -15 -3 -18 4 -18 34 l0 38 -27 -26 c-16 -14 -36
-28 -45 -31 -10 -4 -18 -14 -18 -23 0 -10 5 -14 15 -10 8 3 26 0 40 -6 19 -8
31 -8 51 1 18 8 30 8 38 2 14 -12 84 -12 107 0 9 5 20 4 27 -3 5 -5 28 -9 50
-6 l40 3 -24 -41 c-30 -50 -32 -94 -5 -94 26 0 37 18 45 71 4 27 15 54 26 64
26 23 25 38 0 30 -18 -6 -20 -3 -14 19 9 38 -12 32 -27 -6 l-13 -33 -4 38 c-3
40 -22 52 -22 15 0 -33 -15 -52 -35 -44 -12 4 -16 15 -13 38 3 29 0 33 -22 33
-19 0 -29 -9 -44 -38 -13 -28 -23 -37 -39 -35 -28 4 -34 28 -8 35 12 3 21 11
21 19 0 8 -6 11 -16 7 -13 -5 -14 0 -9 31 8 42 -1 48 -15 10z m99 -86 c-12
-20 -14 -21 -17 -5 -2 10 2 25 9 34 18 21 23 0 8 -29z m-179 2 c0 -5 -5 -10
-11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m294 -107 c-8 -35
-24 -54 -24 -29 0 18 17 56 26 56 2 0 1 -12 -2 -27z"/>
<path d="M4336 2631 c-4 -5 -2 -12 3 -15 5 -4 12 -2 15 3 4 5 2 12 -3 15 -5 4
-12 2 -15 -3z"/>
<path d="M4375 2631 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z"/>
</g>
</svg>




    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
